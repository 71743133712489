import React, {useState, useEffect} from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import {navigate} from 'gatsby-link'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Recaptcha from 'react-recaptcha'

import PortableText from '../serializers/portableText'
import Container from '../container'
import styles from './page.module.css'

import './form.css'

const Page = ({title, _rawBody, _rawBottomFormBody, thankYou, emailto, subject}) => {
  const data = useStaticQuery(graphql`
    {
      allSanityJob(filter: {language: {eq: "en"}}) {
        edges {
          node {
            _id
            title
            slug {
              current
            }
          }
        }
      }
    }
  `)

  function encode (data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  // State
  const [formValues, setFormValues] = useState([])
  const [isVerified, setIsVerified] = useState(false)
  const [formErrors, setFormErrors] = useState(false)
  const [recaptchaResponse, setRecaptchaResponse] = useState(null)
  // const [selectedJob, setSelectedJob] = useState('')

  if (typeof window !== `undefined`) {
    const urlParams = new URLSearchParams(window.location.search)
    const jobParam = urlParams.get('jobID')
    // console.log({jobParam})
    useEffect(() => {
      setFormValues({...formValues, jobApplyingFor: jobParam})
    }, [])
  }

  // Google Recaptcha
  let recaptchaLoaded = function () {
    console.log('Loaded')
  }
  let expiredCallback = function () {
    console.log('expired')
    setIsVerified(false)
    setRecaptchaResponse(null)
  }
  let verifyCallback = function (response) {
    console.log(response)
    if (response) {
      setIsVerified(true)
      setRecaptchaResponse(response)
    } else {
      setIsVerified(false)
      setRecaptchaResponse(null)
    }
  }

  const handleSelectedJobChange = e => {
    // setSelectedJob(e.target.value)
    setFormValues({...formValues, [e.target.name]: e.target.value})
  }

  // Start Date
  const handleDateChange = (date) => {
    // .toLocaleString()
    setFormValues({...formValues, dateAvailableDateTime: date, dateAvailableDateTime_: date.toLocaleString()})
  }
  // Employment One Start Date
  const handleEmployerOneStartDateofEmploymentChange = (date) => {
    // .toLocaleString()
    setFormValues({...formValues, employerOneStartDateofEmployment: date, employerOneStartDateofEmployment_: date.toLocaleString()})
  }
  // Employment One End Date
  const handleEmployerOneEndDateofEmploymentChange = (date) => {
    // .toLocaleString()
    setFormValues({...formValues, employerOneEndDateofEmployment: date, employerOneEndDateofEmployment_: date.toLocaleString()})
  }
  // Employment Two Start Date
  const handleEmployerTwoStartDateofEmploymentChange = (date) => {
    // .toLocaleString()
    setFormValues({...formValues, employerTwoStartDateofEmployment: date, employerTwoStartDateofEmployment_: date.toLocaleString()})
  }
  // Employment Two End Date
  const handleEmployerTwoEndDateofEmploymentChange = (date) => {
    // .toLocaleString()
    setFormValues({...formValues, employerTwoEndDateofEmployment: date, employerTwoEndDateofEmployment_: date.toLocaleString()})
  }
  // General form changes
  const handleChange = e => {
    setFormValues({...formValues, [e.target.name]: e.target.value})
  }
  // Submit your form
  const handleSubmit = e => {
    e.preventDefault()
    // console.log({e})

    if (isVerified && formValues.firstName && formValues.lastName && formValues.phone && formValues.address && formValues.city && formValues.state && formValues.zip && formValues.jobApplyingFor && formValues.proofYouEligible && formValues.validDriversLicence && formValues.rateofPayAcceptable && formValues.dateAvailableDateTime && formValues.currentlyEmployed && formValues.initials) {
      console.log('valid form')
      console.log({formValues})
      setFormErrors(false)

      const form = e.target
      fetch('/.netlify/functions/email', {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body: encode({
          'to': emailto,
          'from': 'no-reply@lindgrenlandscape.com',
          'subject': subject,
          ...formValues,
          recaptcha: recaptchaResponse
        })
      })
        .then(() => navigate(form.getAttribute('action')))
        .catch(error => console.error(error))
    } else {
      console.log('invalid form')
      setFormErrors(true)
    }
  }

  return (
    <>

      <article className={styles.root}>
        <Container>

          <div className={styles.pageTitleWrapper}>
            <h1 className={styles.pageTitle}>{title}</h1>
          </div>
          <div className={styles.mainContent}>
            {_rawBody && <PortableText blocks={_rawBody} />}
          </div>

          <div className={styles.formWrapper}>

            <form
              name={'lindgrenlandscape.com Contact Form'}
              method='post'
              action={thankYou}
              onSubmit={handleSubmit}
            >

              <div hidden>
                <label>
                    Don’t fill this out:{' '}
                  <input name={'bot-field'} onChange={handleChange} />
                </label>
              </div>

              <h3>Personal Information</h3>

              <div className={styles.grid3}>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'firstName'}>
                    Your First Name <span className={styles.formRequired}>*</span>
                  </label>
                  <div className='control'>
                    <input
                      className={styles.customInput}
                      type={'text'}
                      name={'firstName'}
                      onChange={handleChange}
                      id={'firstName'}
                      required
                    />
                  </div>
                </div>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'middleName'}>
                    Your Middle Name
                  </label>
                  <div className='control'>
                    <input
                      className={styles.customInput}
                      type={'text'}
                      name={'middleName'}
                      onChange={handleChange}
                      id={'middleName'}
                    />
                  </div>
                </div>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'lastName'}>
                    Your Last Name <span className={styles.formRequired}>*</span>
                  </label>
                  <div className='control'>
                    <input
                      className={styles.customInput}
                      type={'text'}
                      name={'lastName'}
                      onChange={handleChange}
                      id={'lastName'}
                    />
                  </div>
                </div>

              </div>

              <div className={styles.grid2}>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'email'}>
                    Email
                  </label>
                  <div className='control'>
                    <input
                      className={styles.customInput}
                      type={'email'}
                      name={'email'}
                      onChange={handleChange}
                      id={'email'}
                    />
                  </div>
                </div>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'phone'}>
                    Phone <span className={styles.formRequired}>*</span>
                  </label>
                  <div className='control'>
                    <input
                      className={styles.customInput}
                      type={'text'}
                      name={'phone'}
                      onChange={handleChange}
                      id={'phone'}

                    />
                  </div>
                </div>

              </div>

              <div className='field'>
                <label className={styles.customLabel} htmlFor={'address'}>
                Address <span className={styles.formRequired}>*</span>
                </label>
                <div className='control'>
                  <input
                    className={styles.customInput}
                    type={'text'}
                    name={'address'}
                    onChange={handleChange}
                    id={'address'}
                  />
                </div>
              </div>

              <div className={styles.grid3}>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'city'}>
                City <span className={styles.formRequired}>*</span>
                  </label>
                  <div className='control'>
                    <input
                      className={styles.customInput}
                      type={'text'}
                      name={'city'}
                      onChange={handleChange}
                      id={'city'}
                    />
                  </div>
                </div>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'state'}>
                State <span className={styles.formRequired}>*</span>
                  </label>
                  <div className='control'>
                    <input
                      className={styles.customInput}
                      type={'text'}
                      name={'state'}
                      onChange={handleChange}
                      id={'state'}
                    />
                  </div>
                </div>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'zip'}>
                Zip <span className={styles.formRequired}>*</span>
                  </label>
                  <div className='control'>
                    <input
                      className={styles.customInput}
                      type={'text'}
                      name={'zip'}
                      onChange={handleChange}
                      id={'zip'}
                    />
                  </div>
                </div>

              </div>

              <hr className={styles.customHR} />

              <h3>Job</h3>

              <div className={styles.grid2}>

                <div className='field'>
                  <label
                    className={styles.customLabel}
                    htmlFor='jobApplyingFor'
                  >Job Applying for  <span className={styles.formRequired}>*</span></label>
                  <select id='jobApplyingFor' name='jobApplyingFor' className={styles.customSelect} onChange={handleSelectedJobChange} value={formValues.jobApplyingFor || ''}>
                    <option value='' defaultValue className={styles.customSelectDisabled}> - Choose a Job - </option>
                    {data.allSanityJob.edges.map(job => (
                      <option value={job.node.slug.current} key={job.node.slug.current}>{job.node.title}</option>
                    ))}
                    <option value='other'>Other</option>
                  </select>

                  {/* Other Job */}
                  {formValues.jobApplyingFor === 'other' && (
                    <div>
                      <label className={styles.visuallyHidden} htmlFor={'jobApplyingForOther'}>Other Job Position</label>
                      <div className='control' style={{marginTop: '5px'}}>
                        <input
                          className={styles.customInput}
                          type={'text'}
                          name={'jobApplyingForOther'}
                          onChange={handleChange}
                          id={'jobApplyingForOther'}
                          placeholder='Enter the job position'
                        />
                      </div>
                    </div>
                  )}

                </div>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'whereHearAboutPosition'}>
                Where did you hear about this position?
                  </label>
                  <div className='control'>
                    <input
                      className={styles.customInput}
                      type={'text'}
                      name={'whereHearAboutPosition'}
                      onChange={handleChange}
                      id={'whereHearAboutPosition'}
                    />
                  </div>
                </div>

              </div>

              <div className={styles.grid2}>

                <div className='field'>
                  <label
                    className={styles.customLabel}
                    htmlFor='proofYouEligible'
                  >If hired, can you furnish proof you are eligible to work in the U.S. <span className={styles.formRequired}>*</span></label>
                  <select id='proofYouEligible' name='proofYouEligible' className={styles.customSelect} required onChange={handleChange}>
                    <option value='' defaultValue className={styles.customSelectDisabled}> - Choose- </option>
                    <option value='yes'>Yes</option>
                    <option value='no'>No</option>
                  </select>
                </div>

                <div className='field'>
                  <label
                    className={styles.customLabel}
                    htmlFor='validDriversLicence'
                  >Do you have a valid U.S. driver's licence?  <span className={styles.formRequired}>*</span></label>
                  <select id='validDriversLicence' name='validDriversLicence' className={styles.customSelect} required onChange={handleChange}>
                    <option value='' defaultValue className={styles.customSelectDisabled}> - Choose- </option>
                    <option value='yes'>Yes</option>
                    <option value='no'>No</option>
                  </select>
                </div>

              </div>

              <hr className={styles.customHR} />

              <h3>Job Information</h3>

              <div className={styles.grid2}>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'rateofPayAcceptable'}>
                  Rate of pay acceptable <span className={styles.formRequired}>*</span>
                  </label>
                  <div className='control'>
                    <input
                      className={styles.customInput}
                      type={'text'}
                      name={'rateofPayAcceptable'}
                      onChange={handleChange}
                      id={'rateofPayAcceptable'}
                    />
                  </div>
                </div>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'dateAvailable'}>
                Date Available <span className={styles.formRequired}>*</span>
                  </label>
                  <div className='control'>
                    <DatePicker
                      className={styles.customInput}
                      selected={formValues.dateAvailableDateTime}
                      onChange={handleDateChange}
                      onSelect={handleDateChange}
                      dateFormat='MMMM d, yyyy'
                      name='dateAvailable'
                      id='dateAvailable'
                      required
                      autocomplete='false'
                      minDate={new Date()}
                    />
                  </div>

                </div>

              </div>

              <div className={styles.grid2}>

                <div className='field'>
                  <label
                    className={styles.customLabel}
                    htmlFor='currentlyEmployed'
                  >Are you currently employed? <span className={styles.formRequired}>*</span></label>
                  <select id='currentlyEmployed' name='currentlyEmployed' className={styles.customSelect} required onChange={handleChange}>
                    <option value='' defaultValue className={styles.customSelectDisabled}> - Choose- </option>
                    <option value='yes'>Yes</option>
                    <option value='no'>No</option>
                  </select>
                </div>

                <div className='field'>
                  <label
                    className={styles.customLabel}
                    htmlFor='referToPresentEmployer'
                  >If yes, may we refer to your present employer? </label>
                  <select id='referToPresentEmployer' name='referToPresentEmployer' className={styles.customSelect} required onChange={handleChange}>
                    <option value='' defaultValue className={styles.customSelectDisabled}> - Choose- </option>
                    <option value='yes'>Yes</option>
                    <option value='no'>No</option>
                  </select>
                </div>

              </div>

              <hr className={styles.customHR} />

              <h3>Highest Education Completed</h3>

              <div className={styles.grid2}>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'highestEducationCompletedSchool'}>
                  School Name
                  </label>
                  <div className='control'>
                    <input
                      className={styles.customInput}
                      type={'text'}
                      name={'highestEducationCompletedSchool'}
                      onChange={handleChange}
                      id={'highestEducationCompletedSchool'}
                    />
                  </div>
                </div>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'highestEducationCompletedSchoolAddress'}>
                  School Address, City, State
                  </label>
                  <div className='control'>
                    <input
                      className={styles.customInput}
                      type={'text'}
                      name={'highestEducationCompletedSchoolAddress'}
                      onChange={handleChange}
                      id={'highestEducationCompletedSchoolAddress'}
                    />
                  </div>
                </div>

              </div>

              <div className={styles.grid2}>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'highestEducationCompletedSchoolMajorMinor'}>
                  Major/Minor
                  </label>
                  <div className='control'>
                    <input
                      className={styles.customInput}
                      type={'text'}
                      name={'highestEducationCompletedSchoolMajorMinor'}
                      onChange={handleChange}
                      id={'highestEducationCompletedSchoolMajorMinor'}
                    />
                  </div>
                </div>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'highestEducationCompletedSchoolDegree'}>
                  Degree
                  </label>
                  <div className='control'>
                    <input
                      className={styles.customInput}
                      type={'text'}
                      name={'highestEducationCompletedSchoolDegree'}
                      onChange={handleChange}
                      id={'highestEducationCompletedSchoolDegree'}
                    />
                  </div>
                </div>

              </div>

              <hr className={styles.customHR} />

              <h3>Previous Employer Section</h3>
              <h4>Employer #1</h4>
              <div className={styles.grid3}>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'employerOneCompanyName'}>
                  Company Name
                  </label>
                  <div className='control'>
                    <input
                      className={styles.customInput}
                      type={'text'}
                      name={'employerOneCompanyName'}
                      onChange={handleChange}
                      id={'employerOneCompanyName'}
                    />
                  </div>
                </div>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'employerOnePhoneNumber'}>
                  Phone Number
                  </label>
                  <div className='control'>
                    <input
                      className={styles.customInput}
                      type={'text'}
                      name={'employerOnePhoneNumber'}
                      onChange={handleChange}
                      id={'employerOnePhoneNumber'}
                    />
                  </div>
                </div>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'employerOneAddress'}>
                  Address, City, State
                  </label>
                  <div className='control'>
                    <input
                      className={styles.customInput}
                      type={'text'}
                      name={'employerOneAddress'}
                      onChange={handleChange}
                      id={'employerOneAddress'}
                    />
                  </div>
                </div>

              </div>

              <div className={styles.grid3}>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'employerOneJobTitle'}>
                  Job Title
                  </label>
                  <div className='control'>
                    <input
                      className={styles.customInput}
                      type={'text'}
                      name={'employerOneJobTitle'}
                      onChange={handleChange}
                      id={'employerOneJobTitle'}
                    />
                  </div>
                </div>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'employerOneSupervisorName'}>
                  Supervisor Name
                  </label>
                  <div className='control'>
                    <input
                      className={styles.customInput}
                      type={'text'}
                      name={'employerOneSupervisorName'}
                      onChange={handleChange}
                      id={'employerOneSupervisorName'}
                    />
                  </div>
                </div>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'employerOneReasonforLeaving'}>
                  Reason for Leaving
                  </label>
                  <div className='control'>
                    <input
                      className={styles.customInput}
                      type={'text'}
                      name={'employerOneReasonforLeaving'}
                      onChange={handleChange}
                      id={'employerOneReasonforLeaving'}
                    />
                  </div>
                </div>

              </div>

              <div className={styles.grid3}>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'employerOneDescriptionofDuties'}>
                    Description of Duties
                  </label>
                  <div className='control'>
                    <input
                      className={styles.customInput}
                      type={'text'}
                      name={'employerOneDescriptionofDuties'}
                      onChange={handleChange}
                      id={'employerOneDescriptionofDuties'}
                    />
                  </div>
                </div>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'employerOneStartDateofEmployment'}>
                    Start Date of Employment
                  </label>
                  <div className='control'>

                    <DatePicker
                      className={styles.customInput}
                      selected={formValues.employerOneStartDateofEmployment}
                      onChange={handleEmployerOneStartDateofEmploymentChange}
                      onSelect={handleEmployerOneStartDateofEmploymentChange}
                      dateFormat='MMMM d, yyyy'
                      name={'employerOneStartDateofEmployment'}
                      id={'employerOneStartDateofEmployment'}
                      autocomplete='false'
                    />
                  </div>
                </div>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'employerOneEndDateofEmployment'}>
                    End Date of Employment
                  </label>
                  <div className='control'>

                    <DatePicker
                      className={styles.customInput}
                      selected={formValues.employerOneEndDateofEmployment}
                      onChange={handleEmployerOneEndDateofEmploymentChange}
                      onSelect={handleEmployerOneEndDateofEmploymentChange}
                      dateFormat='MMMM d, yyyy'
                      name={'employerOneEndDateofEmployment'}
                      id={'employerOneEndDateofEmployment'}
                      autocomplete='false'
                    />
                  </div>
                </div>

              </div>

              <h4>Employer #2</h4>
              <div className={styles.grid3}>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'employerTwoCompanyName'}>
  Company Name
                  </label>
                  <div className='control'>
                    <input
                      className={styles.customInput}
                      type={'text'}
                      name={'employerTwoCompanyName'}
                      onChange={handleChange}
                      id={'employerTwoCompanyName'}
                    />
                  </div>
                </div>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'employerTwoPhoneNumber'}>
  Phone Number
                  </label>
                  <div className='control'>
                    <input
                      className={styles.customInput}
                      type={'text'}
                      name={'employerTwoPhoneNumber'}
                      onChange={handleChange}
                      id={'employerTwoPhoneNumber'}
                    />
                  </div>
                </div>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'employerTwoAddress'}>
  Address, City, State
                  </label>
                  <div className='control'>
                    <input
                      className={styles.customInput}
                      type={'text'}
                      name={'employerTwoAddress'}
                      onChange={handleChange}
                      id={'employerTwoAddress'}
                    />
                  </div>
                </div>

              </div>

              <div className={styles.grid3}>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'employerTwoJobTitle'}>
  Job Title
                  </label>
                  <div className='control'>
                    <input
                      className={styles.customInput}
                      type={'text'}
                      name={'employerTwoJobTitle'}
                      onChange={handleChange}
                      id={'employerTwoJobTitle'}
                    />
                  </div>
                </div>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'employerTwoSupervisorName'}>
  Supervisor Name
                  </label>
                  <div className='control'>
                    <input
                      className={styles.customInput}
                      type={'text'}
                      name={'employerTwoSupervisorName'}
                      onChange={handleChange}
                      id={'employerTwoSupervisorName'}
                    />
                  </div>
                </div>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'employerTwoReasonforLeaving'}>
  Reason for Leaving
                  </label>
                  <div className='control'>
                    <input
                      className={styles.customInput}
                      type={'text'}
                      name={'employerTwoReasonforLeaving'}
                      onChange={handleChange}
                      id={'employerTwoReasonforLeaving'}
                    />
                  </div>
                </div>

              </div>

              <div className={styles.grid3}>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'employerTwoDescriptionofDuties'}>
    Description of Duties
                  </label>
                  <div className='control'>
                    <input
                      className={styles.customInput}
                      type={'text'}
                      name={'employerTwoDescriptionofDuties'}
                      onChange={handleChange}
                      id={'employerTwoDescriptionofDuties'}
                    />
                  </div>
                </div>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'employerTwoStartDateofEmployment'}>
    Start Date of Employment
                  </label>
                  <div className='control'>

                    <DatePicker
                      className={styles.customInput}
                      selected={formValues.employerTwoStartDateofEmployment}
                      onChange={handleEmployerTwoStartDateofEmploymentChange}
                      onSelect={handleEmployerTwoStartDateofEmploymentChange}
                      dateFormat='MMMM d, yyyy'
                      name={'employerTwoStartDateofEmployment'}
                      id={'employerTwoStartDateofEmployment'}
                      autocomplete='false'
                    />
                  </div>
                </div>

                <div className='field'>
                  <label className={styles.customLabel} htmlFor={'employerTwoEndDateofEmployment'}>
    End Date of Employment
                  </label>
                  <div className='control'>

                    <DatePicker
                      className={styles.customInput}
                      selected={formValues.employerTwoEndDateofEmployment}
                      onChange={handleEmployerTwoEndDateofEmploymentChange}
                      onSelect={handleEmployerTwoEndDateofEmploymentChange}
                      dateFormat='MMMM d, yyyy'
                      name={'employerTwoEndDateofEmployment'}
                      id={'employerTwoEndDateofEmployment'}
                      autocomplete='false'
                    />
                  </div>
                </div>

              </div>

              <hr className={styles.customHR} />

              {_rawBottomFormBody && <PortableText blocks={_rawBottomFormBody} />}

              <div className='field'>
                <label className={styles.customLabel} htmlFor={'initials'}>
                Initials <span className={styles.formRequired}>*</span>
                </label>
                <div className='control'>
                  <input
                    className={styles.customInput}
                    type={'text'}
                    name={'initials'}
                    onChange={handleChange}
                    id={'initials'}
                  />
                </div>
              </div>

              <div>
                <br />
                <Recaptcha
                  sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_PUBLIC}
                  render='explicit'
                  verifyCallback={verifyCallback}
                  onloadCallback={recaptchaLoaded}
                  expiredCallback={expiredCallback}
                />
              </div>

              <div className='field'>
                <button disabled={formValues.firstName && formValues.lastName && formValues.phone && formValues.address && formValues.city && formValues.state && formValues.zip && formValues.jobApplyingFor && formValues.proofYouEligible && formValues.validDriversLicence && formValues.rateofPayAcceptable && formValues.dateAvailableDateTime && formValues.currentlyEmployed && formValues.initials ? false : 'disabled'} className={styles.customButton} type='submit'>
                    Send
                </button>
              </div>
              {/* <pre>{JSON.stringify(formValues, null, 4)}</pre> */}
              {formErrors && (
                <div className={styles.errors}><p>Unable to submit form. Please make sure all of your fields are filled out.</p></div>
              )}

            </form>
          </div>

        </Container>
      </article>
    </>
  )
}
export default Page
